.pa-filter__bytitle {
  margin-bottom: 15px;
}

.pa-filter__bytitle-head {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 5px;
}

.pa-filter__bytitle-button {
  font-size: 14px;
  padding: 8px;
  margin-left: 10px;
  cursor: pointer;
}

.pa-filter__bytitle-input {
  padding: 8px;
  font-size: 14px;
}