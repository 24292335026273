.pa-photo-grid__list-item__image {
  width: 100%;
  height: auto;
}

.pa-photo-grid {
  margin-bottom: 30px;
}

.pa-photo-grid__list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 28px 1fr 28px 1fr 28px 1fr;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 28px;
}

@media (min-width: 200px) {
  .pa-photo-grid__list {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr;
    grid-template-columns: repeat(1, 1fr);
    grid-column-gap: 28px
  }
}

@media (min-width: 576px) {
  .pa-photo-grid__list {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr 28px 1fr;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 28px
  }
}

@media (min-width: 992px) {
  .pa-photo-grid__list {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr 28px 1fr 28px 1fr 28px 1fr;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 28px
  }
}

.pa-photo-grid__list-item {
  margin-bottom: 30px;
  height: max-content;
}

.pa-photo-grid__list-item__wrapper {
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 7px 25px 0 rgba(0, 0, 0, .08);
  margin-bottom: 15px;
}

.pa-photo-grid__list-item__title {
  margin: 0;
  padding: 15px 15px 0 15px;
  height: 70px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.pa-photo-grid__list-item__button {
  width: 50px;
  margin-left: auto;
  padding: 5px 15px 15px 15px;
}

.pa-photo-grid__list-item__button-favorite {
  cursor: pointer;
  display: inline-block;
  width: 20px;
  height: 20px;
  background-size: 20px 20px;
  background-image: url("data:image/svg+xml,%3Csvg width='1em' height='1em' viewBox='0 0 16 16' class='bi bi-star-fill' fill='%0A%23959595' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.283.95l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z'/%3E%3C/svg%3E");
}

.pa-photo-grid__list-item__button-favorite--active {
  background-image: url("data:image/svg+xml,%3Csvg width='1em' height='1em' viewBox='0 0 16 16' class='bi bi-star-fill' fill='%0A%2335affd' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.283.95l-3.523 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z'/%3E%3C/svg%3E");
}

.pa-photo-grid__list-item__button-comment {
  margin-right: 10px;
  cursor: pointer;
  display: inline-block;
  width: 20px;
  height: 20px;
  background-size: 20px 20px;
  background-image: url("data:image/svg+xml,%3Csvg width='1em' height='1em' viewBox='0 0 16 16' class='bi bi-chat-fill' fill='%23959595' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8 15c4.418 0 8-3.134 8-7s-3.582-7-8-7-8 3.134-8 7c0 1.76.743 3.37 1.97 4.6-.097 1.016-.417 2.13-.771 2.966-.079.186.074.394.273.362 2.256-.37 3.597-.938 4.18-1.234A9.06 9.06 0 0 0 8 15z'/%3E%3C/svg%3E");
}

.pa-photo__comment {
  padding: 15px;
  background: #f3f3f3;
  border-radius: 12px;
}

.pa-photo__comment textarea {
  width: 100%;
  height: 55px;
  font-size: 14px;
  margin-bottom: 5px;
  padding: 5px;
}

.pa-photo__comment-list {
  padding: 0;
  list-style: none;
  margin-bottom: 10px;
  max-height: 200px;
  overflow-y: auto;
}

.pa-photo__comment-list li {
  font-size: 14px;
  font-family: 'Roboto';
  border-radius: 5px;
  background-color: #dfdfdf;
  margin-bottom: 5px;
  padding: 5px;
}

.pa-photo__comment-submit-button {
  font-size: 15px;
}