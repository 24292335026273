.pa-footer__text {
  text-align: center;
  font-family: 'Montserrat';
  font-size: 14px;
  line-height: normal;
  padding: 15px 0;
}

.pa-footer {
  border-top: 1px solid #f4f4f4;
}