.pa-photo-grid__list-item__album-head {
  padding: 0 15px;
  font-size: 12px;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 5px;
}

.pa-photo-grid__list-item__album-name {
  padding: 0 15px;
  font-size: 12px;
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 20px;
  font-style: italic;
  color: #35affd;
}

.pa-photo-grid__list-no-item {
  margin-bottom: 50px;
  text-align: center;
  font-weight: 500;
}