.pa-album-grid__list-item {
  border-radius: 12px;
  padding: 15px;
  margin-bottom: 30px;
  background-color: #fff;
  -webkit-box-shadow: 0 7px 25px 0 rgba(0, 0, 0, .08);
  box-shadow: 0 7px 25px 0 rgba(0, 0, 0, .08);
}

.pa-album-grid__list-item__title {
  display: block;
}

.pa-album-grid__list-item__title {
  color: #000000;
  font-family: 'Montserrat';
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 10px;
  line-height: normal;
  cursor: pointer;
  height: 40px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.pa-album-grid__list-item__user {
  font-family: 'Roboto';
  font-size: 14px;
  color: #35affd;
  cursor: pointer;
}

.pa-album-grid__list-item__title,
.pa-album-grid__list-item__user {
  text-decoration: none;
}