.pa-heading {
  margin-top: 50px;
  margin-bottom: 50px;
}

.pa-heading__title {
  margin: 0 0 10px 0;
  text-align: center;
  font-family: 'Montserrat';
  font-size: 32px;
  font-weight: bold;
}

.pa-heading__caption {
  margin: 0;
  text-align: center;
  font-family: 'Roboto';
  font-size: 16px;
}

.pa-album-grid {
  margin-bottom: 30px;
}

.pa-album-grid__list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 28px 1fr 28px 1fr 28px 1fr;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 28px;
}

.pa-filter {
  margin-bottom: 50px;
}

@media (min-width: 200px) {
  .pa-album-grid__list {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr;
    grid-template-columns: repeat(1, 1fr);
    grid-column-gap: 28px
  }
}

@media (min-width: 576px) {
  .pa-album-grid__list {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr 28px 1fr;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 28px
  }
}

@media (min-width: 992px) {
  .pa-album-grid__list {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr 28px 1fr 28px 1fr 28px 1fr;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 28px
  }
}