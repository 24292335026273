.pa-filter__byusername-head {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 5px;
}

.pa-filter__byusername-button {
  font-size: 14px;
  padding: 8px;
  margin-left: 10px;
  cursor: pointer;
}

.pa-filter__byusername-select {
  padding: 8px;
  font-size: 14px;
}