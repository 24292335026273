.pa-navbar {
  background-color: #ffffff;
  border-bottom: 1px solid #f4f4f4;
}

.pa-navbar__nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
}

.pa-navbar__nav-logo {
  font-family: 'Montserrat';
  font-size: 24px;
  font-weight: bold;
  text-decoration: none;
  color: #000000;
}

.pa-navbar__nav-link {
  color: #454545;
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
  margin-left: 20px;
}

.pa-navbar__nav-link:hover {
  color: #35affd;
}

@media (max-width: 575px) {
  .pa-navbar__nav-left {
    display: block;
  }

  .pa-navbar__nav {
    display: block;
    padding: 15px;
    height: auto;
  }

  .pa-navbar__nav-logo {
    display: block;
    margin-bottom: 10px;
  }

  .pa-navbar__nav-link:first-child {
    margin-left: 0;
  }
}