.pa-user-info {
  margin-bottom: 50px;
}

.pa-user-info__title,
.pa-album-info__title {
  font-size: 20px;
  margin-bottom: 20px;
}

.pa-user-info__detail {
  margin: 0;
  padding: 0;
  list-style: none;
}

.pa-user-info__detail-list__head {
  font-size: 16px;
  font-weight: bold;
  font-family: 'Roboto';
  margin: 0 0 6px 0;
}

.pa-user-info__detail-list__content {
  font-size: 16px;
  font-family: 'Roboto';
  margin: 0;
}

.pa-user-info__detail-list {
  margin-bottom: 20px;
}

.pa-user-info__detail-list__content p {
  margin: 0 0 5px 0;
  font-size: 16px;
  font-family: 'Roboto';
}